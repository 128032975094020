import { mdiClose, mdiFlash } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const LoginWindow = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PinPad = styled.div`
  background-color: white;
  height: 62vh;
  width: 40vw;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
`;
const StyledImg = styled.img`
  width: 5vh;
  height: 5vh;
  margin: 2vh 0.5vh 0.5vh 0.5vh;
  align-self: center;
`;
const LineContainer = styled.div`
  width: 70%;
  color: #4a6283;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vh;
`;
const Button = styled.button`
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: 3vh;
  color: #4a6283;
  padding: 2vh;
`;
const Input = styled.div`
  display: flex;
  width: 100%;
  border-style: solid;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
  height: 10vh;
  color: black;
`;

const Login = () => {
  const [loggedIn, setLoggedIn] = useState([false, false, false]);
  const [password, setPassword] = useState("1966");
  const [passwordInput, setPasswordInput] = useState("");
  const navigate = useNavigate();
  function handlePin(e) {
    setPasswordInput((prev) => prev + e.target.innerHTML);

    console.log(passwordInput, password, loggedIn);
  }
  useEffect(() => {
    if (localStorage.getItem("loggedIn") === "true") {
      navigate("/edabstatus");
    }
    if (passwordInput.length === 4) {
      if (passwordInput === password) {
        setLoggedIn(true);
        navigate("/edabstatus");
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("admin", false);
      } else if (passwordInput === "2846") {
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("admin", true);
        navigate("/edabstatus");
      } else {
        setPasswordInput("");
      }
    }

    return () => {};
  }, [passwordInput]);

  return (
    <LoginWindow>
      <PinPad>
        <StyledImg
          src={"https://edab.online/images/" + "EDAB" + ".png"}
          alt="☺"
        />
        <LineContainer>
          <Input>
            {passwordInput.length > 0 &&
              passwordInput.split("").map((i, index) => <p key={index}>✹</p>)}
          </Input>
        </LineContainer>
        <LineContainer>
          <Button onClick={handlePin}>1</Button>
          <Button onClick={handlePin}>2</Button>
          <Button onClick={handlePin}>3</Button>
        </LineContainer>
        <LineContainer>
          <Button onClick={handlePin}>4</Button>
          <Button onClick={handlePin}>5</Button>
          <Button onClick={handlePin}>6</Button>
        </LineContainer>
        <LineContainer>
          <Button onClick={handlePin}>7</Button>
          <Button onClick={handlePin}>8</Button>
          <Button onClick={handlePin}>9</Button>
        </LineContainer>
        <LineContainer>
          <Button
            style={{ lineHeight: "1vh", width: "43%" }}
            onClick={() => setPasswordInput("")}
          >
            &#x1d54f;
          </Button>
          <Button onClick={handlePin}>0</Button>
        </LineContainer>
      </PinPad>
    </LoginWindow>
  );
};

export default Login;
