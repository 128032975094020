import { motion, useCycle } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MenuToggle } from "./MenuToggle";
import Navigation from "./Navigation";
import { isMobile } from "react-device-detect";
import Content from "./Content";
import { mdiArrowUp } from "@mdi/js";
import Icon from "@mdi/react";
import PullToRefresh from "react-simple-pull-to-refresh";


const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
`;
const NavigationPanel = styled(motion.div)`
  overflow-y: scroll;
  z-index: 2;
  color: black;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #fff;
  border-radius: 0;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 4.9vh;
`;
const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2%;
  background-color: #fff;
  border-radius: 0;
  position: fixed;
  bottom: 0;
  z-index: 2;
  color: #4a6283;
`;

const sidebar = {
  open: (height = 1000) => ({
    width: "100%",
    transition: {
      type: "spring",
      stiffness: 20,
      damping: 10,
    },
  }),
  closed: {
    scale: 0,
    width: "0%",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 30,
    },
  },
};

const Status = () => {
  const [facilityNames, setFacilityNames] = useState(null);
  const [facilityLanes, setFacilityLanes] = useState(null);
  const [isOpen, toggleOpen] = useCycle(true, false);

  const [chosenFacility, setChosenFacility] = useState(null);
  const [lanes, setLanes] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);

  // const getStatus = useCallback(async () => {
  //   const controller = new AbortController();
  //   const timeout = setTimeout(() => controller.abort(), 5000);

  //   try {
  //     const response = await fetch("https://server.edab.online/getAll", {
  //       signal: controller.signal,
  //     });

  //     clearTimeout(timeout);

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();

  //     setLanes(data);
  //     setTimeStamp(Date.now());

  //     if (!chosenFacility && data.length > 0) {
  //       setChosenFacility(data[0].facility);
  //     }

  //     return data;
  //   } catch (error) {
  //     console.error(
  //       error.name === "AbortError"
  //         ? "Fetch request timed out"
  //         : "Failed to fetch status data:",
  //       error
  //     );
  //   }
  // }, [chosenFacility]);

  const getFacilityNames = useCallback(async () => {
    try {
      const response = await fetch("https://server.edab.online/getFacilityNames");
      const data = await response.json();
      setFacilityNames(data);
    } catch (error) {
      console.error("Failed to fetch facility names:", error);
    }
  }, []);

  const getFacilityLanes = useCallback(async (facility) => {
    try {
      const response = await fetch(
        `https://server.edab.online/getFacility?facility=${facility}`
      );
      const data = await response.json();
      setFacilityLanes(data);
      return data;
    } catch (error) {
      console.error("Failed to fetch facility lanes:", error);
    }
  }, []);

  useEffect(() => {
    getFacilityNames();
  }, [getFacilityNames]);

  const handleConstantRefresh = useCallback(async () => {
    try {
      const newLanes = await getFacilityLanes(chosenFacility);
      if (newLanes) {
        setLanes(newLanes);
      }
    } catch (error) {
      console.error("Failed to refresh lanes:", error);
    }
  }, [getFacilityLanes, chosenFacility]);

  useInterval(handleConstantRefresh, 10000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const handleRefresh = useCallback(async () => {
    const newLanes = await getFacilityLanes(chosenFacility);
    if (newLanes) {
      setLanes(newLanes);
    }
  }, [getFacilityLanes, chosenFacility]);

  function handleToggle(params) {
    getUniqueFacilities(facilityLanes);
    toggleOpen();
  }

  function getUniqueFacilities(status) {
    return status.filter(
      (facility, index, self) =>
        index === self.findIndex((t) => t.facility === facility.facility)
    );
  }
  return isMobile ? (
    <div style={{ width: "100%", height: "100%" }}>
      <PullToRefresh onRefresh={handleRefresh}>
        {facilityLanes && !isOpen && (
          <Content
            iconSize={1.5}
            isMobile
            handleToggle={handleToggle}
            style={{
              width: "100%",
              height: "100%",
            }}
            itemIds={facilityLanes}
          />
        )}
        {facilityNames && (
          <NavigationPanel
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={window.height}
            variants={sidebar}
          >
            <Navigation
              style={{
                width: "100%",
                position: "absolute",

                left: "0px",
              }}
              itemIds={() => {
                return facilityNames;
              }}
              onClick={(e) => {
                if (isMobile) {
                  toggleOpen();
                }
                // console.log("clicked", e);
                setChosenFacility(e);
                // console.log("status: ", facilityLanes);
                getFacilityLanes(e);
                

                setTimeout(() => setLanes(getFacilityLanes(e)), 10);
              }}
            />
          </NavigationPanel>
        )}
      </PullToRefresh>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <MainContainer>
        {facilityNames && (
          <Navigation
            desktop={timeStamp}
            chosen={facilityLanes?.length > 0 ? chosenFacility : false}
            style={{
              width: "20vw",
            }}
            itemIds={() => {
              return facilityNames;
            }}
            onClick={(e) => {
              // console.log("clicked", e);
              setChosenFacility(e);
              // console.log("status: ", status);
              // console.log(
              //   "newLanes: ",
              //   status.filter((lane) => lane.facility === e.facility)
              // );

              setLanes(getFacilityLanes(e));
            }}
          />
        )}

        {facilityLanes && (
          <Content
            chosenFacility={chosenFacility}
            iconSize={2}
            style={{
              height: "100%",
              width: "80vw",
              padding: "1vh",
            }}
            itemIds={facilityLanes}
          />
        )}
      </MainContainer>
    </div>
  );
};

export default Status;
