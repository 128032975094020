import { motion } from "framer-motion";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useState } from "react";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  border-style: solid;
  border-color: white;
  color: #4a6283;
  cursor: pointer;
  font-size: 3vh;
`;
const StyledIcon = styled.div`
  border-style: solid;
`;
const StyledTitle = styled.div`
  border-style: solid;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;

  p {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;
const StyledImg = styled.img`
  width: 5vh;
  height: 5vh;
  margin: 0.5vh;
`;
const StyledImgAlt = styled.div`
  width: 5vh;
  height: 5vh;
  margin: 0.5vh;
  border-style: solid;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vh;
  font-weight: 600;
`;

const colors = ["#559bb9", "#4a6283", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ i, onClick, chosen }) => {
  const [imgError, setImgError] = useState(false);

  const style = {
    outline: ` ${chosen === i ? "4px" : "2px"} solid ${
      chosen === i ? colors[4] : colors[0]
    }`,
    fontSize: isMobile ? "3vh" : "1.5vw",
  };
  return (
    <Container variants={variants} onClick={() => onClick(i)}>
      <StyledTitle
        chosen={chosen === i ? "true" : "false"}
        style={style}
      >
        {!imgError ? (
          <StyledImg
            src={`https://edab.online/images/${i}.png`}
            alt={i[0].toUpperCase()}
            onError={() => setImgError(true)}
            style={{ display: "block" }}
          />
        ) : (
          <StyledImgAlt>{i[0].toUpperCase()}</StyledImgAlt>
        )}
        <p style={{ fontWeight: chosen === i ? 900 : 500 }}>
          {i}
        </p>
        {/* <p style={{ marginRight: "1px" }}>{chosen === i.facility ? "✓" : ""}</p> */}
      </StyledTitle>
    </Container>
  );
};
