import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
`;

const TestList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const TestItem = styled.li`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px;
`;

const TestName = styled.h3`
  margin: 0 0 10px 0;
  color: #007bff;
`;

const TestField = styled.p`
  margin: 5px 0;
  display: flex;
`;

const FieldName = styled.span`
  font-weight: bold;
  width: 150px;
  color: #4a6283;
`;

const FieldValue = styled.span`
  flex: 1;
  color: #4a6283;
`;

const API_BASE_URL = "https://server.edab.online";
// [
//     {
//       name: "TZ",
//       comment: "This is a test protocol entry",
//       BMS: 1,
//       dockingPins: 1,
//       accelerometer: 1,
//       portExpander: 1,
//       timestamp: "2023-05-01 10:00:00",
//       wifi: "-60db",
//       serial: "21123",
//       turnerreed: "1",
//       wheelreed: "1",
//     },
//   ]

const TestProtocolComponent = ({ UID, isProtocol }) => {
  const [testEntries, setTestEntries] = useState([]);

  useEffect(() => {
    fetchTestProtocol();
  }, []);

  const fetchTestProtocol = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/getTestResult?uniqueId=${UID}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch test protocol");
      }
      const data = await response.json();
      setTestEntries(data);
      if (data.length === 0) {
        isProtocol(false);
      } else {
        isProtocol(true);
      }
    } catch (error) {
      console.error("Error fetching test protocol:", error);
    }
  };

  return (
    <Container>
      <TestList>
        {testEntries.map((entry, index) => (
          <TestItem key={index}>
            <TestName>
              {entry.serialNumber +
                (testEntries.length > 1
                  ? " - " + Math.abs(index - testEntries.length)
                  : "")}
            </TestName>
            <TestField>
              <FieldName>Comment:</FieldName>
              <FieldValue>{entry.comment}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>BMS:</FieldName>
              <FieldValue>{Validate(entry.BMS)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Docking Pins:</FieldName>
              <FieldValue>{Validate(entry.dockingPins)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Accelerometer:</FieldName>
              <FieldValue>{Validate(entry.accelerometer)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Port Expander:</FieldName>
              <FieldValue>{Validate(entry.portExpander)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>WiFi Signal:</FieldName>
              <FieldValue>{ValidateWifi(entry.wifi)}</FieldValue>
            </TestField>

            <TestField>
              <FieldName>Turner Reed:</FieldName>
              <FieldValue>{Validate(entry.turnerReed)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Wheel Reed:</FieldName>
              <FieldValue>{Validate(entry.wheelReed)}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Signature:</FieldName>
              <FieldValue>{entry.name.toUpperCase()}</FieldValue>
            </TestField>
            <TestField>
              <FieldName>Date:</FieldName>
              <FieldValue>{entry.time}</FieldValue>
            </TestField>
          </TestItem>
        ))}
      </TestList>
    </Container>
  );
};
function Validate(entry) {
  console.log(entry);
  if (entry) {
    return "✅";
  } else if (entry === null) {
    return "N/A";
  } else {
    return "❌";
  }
}
function ValidateWifi(entry) {
  
  if (entry.includes("Ok")) {
    return "✅ "+entry.split(" ")[1];
  } else if (entry === null) {
    return "N/A";
  } else if(entry.includes("Error")) {
    return "❌ "+entry.split(" ")[1];
  }
}

export default TestProtocolComponent;
