import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Plus, Search } from "lucide-react";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
`;

const AddButton = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  gap: 10px;
`;

const FilterInput = styled(Input)`
  border-radius: 4px;
`;

const FilterSelect = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
`;

const NoteList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const NoteItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const NoteText = styled.span`
  width: 100%;
  text-decoration: ${(props) => (props.completed ? "line-through" : "none")};
  color: ${(props) => (props.completed ? "#888" : "#000")};
`;

const Timestamp = styled.small`
  margin-left: 10px;
  margin-right: 10px;
  color: #888;
  white-space: nowrap;
`;

const API_BASE_URL = "https://server.edab.online";

const NoteComponent = ({ lane, facility, style }) => {
  const [notes, setNotes] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterType, setFilterType] = useState("all");

  useEffect(() => {
    fetchNotes();
    return () => {
      setNotes([]);
    };
  }, [facility]);

  const fetchNotes = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/getComments?facility=${facility}${lane ? "&lane=" + lane : ""}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }
      const data = await response.json();
      setNotes(data);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const addNote = async () => {
    if (inputValue.trim()) {
      const newNote = {
        text: inputValue.trim(),
        completed: false,
        timestamp: new Date().toISOString(),
      };

      try {
        const response = await fetch(`${API_BASE_URL}/addComment`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            type: "lane",
            comment: newNote.text,
            facility: facility,
            lane: lane,
            completed: false,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to add note");
        } else {
          fetchNotes();
        }

        setInputValue("");
      } catch (error) {
        console.error("Error adding note:", error);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addNote();
    }
  };

  const toggleNoteCompletion = async (id) => {
    const noteToUpdate = notes.find((note) => note.id === id);
    if (!noteToUpdate) {
      console.error("Note not found");
      return;
    }

    const updatedNote = { ...noteToUpdate, completed: !noteToUpdate.completed };

    setNotes(notes.map((note) => (note.id === id ? updatedNote : note)));

    try {
      const response = await fetch(`${API_BASE_URL}/updateCommentStatus`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          completed: updatedNote.completed,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update note");
      } else {
        fetchNotes();
      }
    } catch (error) {
      console.error("Error updating note:", error);
      setNotes(
        notes.map((note) =>
          note.id === id ? { ...note, completed: note.completed } : note
        )
      );
    }
  };

  const filteredNotes = notes.filter((note) => {
    const matchesText = note.comment.toLowerCase().includes(filterValue.toLowerCase());
    const matchesCompletion = 
      filterType === "all" ||
      (filterType === "completed" && note.completed) ||
      (filterType === "active" && !note.completed);
    return matchesText && matchesCompletion;
  });

  return (
    <Container style={style}>
      <InputContainer>
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter a new note"
        />
        <AddButton onClick={addNote}>
          <Plus size={20} />
        </AddButton>
      </InputContainer>
      <FilterContainer>
        <FilterInput
          type="text"
          value={filterValue}
          onChange={handleFilterChange}
          placeholder="Filter notes..."
        />
        <Search size={20} style={{ marginLeft: '-30px', alignSelf: 'center', color: '#888' }} />
        <FilterSelect value={filterType} onChange={handleFilterTypeChange}>
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </FilterSelect>
      </FilterContainer>
      <NoteList>
        {filteredNotes.map((note) => (
          <NoteItem key={note.id}>
            <Checkbox
              type="checkbox"
              checked={note.completed}
              onChange={() => toggleNoteCompletion(note.id)}
            />
            <Timestamp>
              {formatElapsedTime(note.created_at)}
            </Timestamp>
            <NoteText completed={note.completed}>{note.comment}</NoteText>
          </NoteItem>
        ))}
      </NoteList>
    </Container>
  );
};

const formatElapsedTime = (timestamp) => {
  const date = new Date(timestamp);
  const timeAgo = new Date().getTime() - date.getTime();
  const seconds = Math.floor(timeAgo / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes} min.`;
  } else {
    return `${seconds} sec.`;
  }
};

export default NoteComponent;