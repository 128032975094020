import logo from "./logo.svg";
import styled, { ThemeProvider, keyframes } from "styled-components";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { GlobalStyles } from "./Styles/Global";
import { useEffect, useState } from "react";
import Status from "./Status";
import Login from "./Login";

const StyledApp = styled.div`
  height: 100vh;
  width: 100vw;
`;

const BuildVersion = styled.h2`
  position: absolute;
  bottom: 0;
  right: 1vw;
`;

function App() {
  return (
    <ThemeProvider
      theme={{
        font: "roboto",
        colors: {
          text: "#000",
          border: "#000",
          primary: "#2a3142",
          highlight: "#4a6283",
          secondary: "#374151",
          background: "#1f2937",
        },
      }}
    >
      <GlobalStyles />
      <StyledApp>
        <Router>
          {process.env.NODE_ENV === "development" && (
            <BuildVersion>{process.env.NODE_ENV}</BuildVersion>
          )}

          <Routes>
            <Route path="/edabStatus" element={<Status />}></Route>
          </Routes>
          <Routes>
            <Route path="/" element={<Login />}></Route>
          </Routes>
        </Router>
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;
