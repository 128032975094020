import { createGlobalStyle } from "styled-components";



export const GlobalStyles = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1{
  letter-spacing:0.3rem;
}
*{
  
  border-width: 1px;
  border-radius: 5px;
  letter-spacing:0.1rem;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline:none;
    ::-webkit-scrollbar {
    display: none;
  }
  
  

}
body {
 
  color: white;
  border-color: ${({ theme }) => theme.colors.border};
  font-family: ${({ theme }) => theme.font};
  
  overflow:hidden;
  
}
`;

