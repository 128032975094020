import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./Menuitem";
import styled from "styled-components";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const UnorderedList = styled(motion.div)`
  overflow-y: scroll;
  padding: 1vh 1vh 1vh 1vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  padding-bottom: 10vh;

  background-color: white;
  z-index: 2;
`;
const Foot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 2%;

  border-radius: 0;
  position: fixed;
  bottom: 0;

  z-index: 2;
  color: #4a6283;
`;
const LogoutBtn = styled.button`
  width: 90%;
  align-self: center;
  display: flex;
  border-style: solid;
  border-color: white;
  color: #4a6283;
  cursor: pointer;
  padding: 1vh;
  border-style: solid;
  border-color: #4a6283;
  border-width: 3px;
  margin-top: 1vh;
  align-content: center;
  justify-content: center;
  font-size: 3vh;
`;
const Navigation = ({ itemIds, onClick, style, chosen, desktop }) => {
  const [facilities, setFacilities] = useState(itemIds || []);
  const [admin, setAdmin] = useState(
    window.localStorage.getItem("admin") === "true" ? true : false
  );

  useEffect(() => {
    // window.localStorage.getItem("admin") === "true" ? setAdmin(true) : setAdmin(false);
    return () => {};
  }, [itemIds]);

  return (
    <UnorderedList variants={variants} style={style}>
      {facilities &&
        facilities.map((item, i) => (
          <MenuItem chosen={chosen} i={item} key={i} onClick={onClick} />
        ))}
      {admin && (
        <LogoutBtn
          onClick={() => {
            console.log("clicked");
            RestartServers();
          }}
        >
          Refresh service
        </LogoutBtn>
      )}
      <LogoutBtn
        onClick={() => {
          localStorage.clear();
          window.location.assign("/");
        }}
      >
        Log out
      </LogoutBtn>
    </UnorderedList>
  );

  async function RestartServers() {
    let response = await fetch("https://server.edab.online/restart", {
      method: "GET",
    });
    console.log(response);
  }
};

export default Navigation;
