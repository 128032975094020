import * as React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mdiHamburger } from "@mdi/js";
import Icon from "@mdi/react";

const Button = styled.div`
  color: #4a6283;
`;

export const MenuToggle = ({ toggle }) => (
  <Button onClick={toggle}>
    <Icon path={mdiHamburger} size={1.5} />
  </Button>
);
