import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled, { css, keyframes } from "styled-components";
import Icon from "@mdi/react";
import {
  mdiBattery,
  mdiLightningBolt,
  mdiListStatus,
  mdiMapMarker,
  mdiRefresh,
  mdiTablet,
  mdiWeb,
} from "@mdi/js";

const Container = styled.div`
  width: 100%;
  display: flex;
  border-style: solid;
  border-color: black;
  cursor: zoom-in;
  background-color: ${({ theme }) => "#fff"};
  padding: 1vh;

  color: #4a6283;

  flex-direction: column;
`;
const SubContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1vh;
  gap: 1vh;
`;
const InnerText = styled.p`
  width: 100%;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
  border-radius: 1vh;
  gap: 1vh;

  /* The following line is the key to enabling text-overflow:ellipsis */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1vh;
`;
const LaneNumber = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1vh;
  padding-right: 1vh;
  align-items: center;
  font-size: 3vh;

  width: 100%;
`;
const LastUpdate = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1vh;
  padding-right: 1vh;
  align-items: center;

  width: 100%;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledIcon = styled(Icon)`
  ${(props) =>
    props.spin &&
    css`
      animation: ${spinAnimation} 1s linear;
    `}
  height:100%;
  width: 5vh;
`;
const colors = ["#2a3142", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const ListItem = ({ i, onClick, onClickTablet }) => {
  const status = i.status;
  const batteryHistory = i.batteryHistory;
  const lastUpdate = new Date(i.lastUpdate);

  const [isSpinning, setIsSpinning] = useState(false);
  const [isTabletOnline, setIsTabletOnline] = useState(false);
  useEffect(() => {
    if (i.tabletIP) {
      checkTabletConnection();
    } else {
      setIsTabletOnline(false);
    }
  }, [i.tabletIP]);
  
  function GetOnline(rTime) {
    //time since last update > 1 minute
    if (rTime) {
      const secondsAgo = Math.abs(
        Math.floor((lastUpdate.getTime() - new Date().getTime()) / 1000)
      );
      if (secondsAgo < 60) {
        return `Last updated: ${secondsAgo} sec. ago`;
      } else if (secondsAgo < 3600) {
        const minutesAgo = Math.abs(Math.floor(secondsAgo / 60));
        return `Last updated: ${minutesAgo} min. ago`;
      } else if (secondsAgo < 86400) {
        const hoursAgo = Math.abs(Math.floor(secondsAgo / 3600));
        return `Last updated: ${hoursAgo}h ago`;
      } else {
        return `Last updated: ${Math.abs(
          Math.floor(secondsAgo / 86400)
        )} day(s) ago`;
      }
    } else if (new Date().getTime() - lastUpdate.getTime() > 60 * 5000) {
      return false;
    } else {
      return true;
    }
  }
  const checkTabletConnection = async () => {
    const ip = i.status.serverIP;
    const ipSuffix = i.tabletIP.split(".")[3];
    const url = `https://server.edab.online/getImageFromFully?ip=${ipSuffix}&server=${ip}&port=2323&timestamp=${Date.now()}`;

    try {
      const response = await fetch(url, { method: "HEAD" });
      setIsTabletOnline(response.ok);
      return response.ok;
    } catch (error) {
      // console.error("Error checking tablet connection:", error);
      setIsTabletOnline(false);
      return false;
    }
  };
  async function Refresh(params) {
    console.log("REFRESH", i);
    try {
      const Response = await fetch("https://server.edab.online/mqttCommand", {
        method: "POST",
        body: JSON.stringify({
          facility: i.status.serverIP,
          lane: i.lane,
          topic: "/retrieverWifi/" + i.lane + "/requestSettings",
          mqttCommand: "requestSettings",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }
  const handleClick = (e) => {
    Refresh();
    e.stopPropagation();
    setIsSpinning(true);
    // Stop the animation after it completes once (2 seconds)
    setTimeout(() => setIsSpinning(false), 2000);
  };

  return (
    <motion.div onClick={() => onClick(i)}>
      {status && batteryHistory && (
        <Container>
          <LaneNumber>
            {i.facility === "Waco" && <p>Box: {status.box}</p>}
            <p>Lane: {i.lane - i.lane.slice(0, -2) * 100}</p>
            {isTabletOnline && (
              <StyledIcon
                style={{ cursor: "pointer" }}
                path={mdiTablet}
                size={1.8}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickTablet(i);
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                gap: "2vh",
                height: "100%",
                width: "20%",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "-2vh",
                cursor: "pointer",
              }}
              onClick={handleClick}
            >
              <StyledIcon path={mdiRefresh} size={1.8} spin={isSpinning} />
            </div>
          </LaneNumber>

          <SubContainer>
            <StyledText>
              <InnerText>
                <Icon path={mdiWeb} size={1} />
                {
                  <span
                    style={
                      !GetOnline() ? { color: "red", fontWeight: "bold" } : {}
                    }
                  >
                    {GetOnline() ? "Online" : "Offline"}
                  </span>
                }
              </InnerText>
              <InnerText>
                <Icon path={mdiBattery} size={1} />
                {batteryHistory[batteryHistory.length - 1]
                  ? batteryHistory[batteryHistory.length - 1].batteryPercent +
                    "%"
                  : "-"}
              </InnerText>
            </StyledText>
            <StyledText>
              <InnerText>
                <Icon path={mdiLightningBolt} size={1} />
                {batteryHistory[batteryHistory.length - 1].charging
                  ? "Charging"
                  : "Not charging"}
              </InnerText>
              <InnerText>
                <Icon path={mdiMapMarker} size={1} />
                {status.stopPosition
                  ? PulseConverter(
                      status.retVersion ? status.retVersion : 2,
                      status.stopPosition,
                      "yd"
                    ) + " yd"
                  : "-"}
              </InnerText>
            </StyledText>
          </SubContainer>
          <LastUpdate>{GetOnline(true)}</LastUpdate>
        </Container>
      )}
    </motion.div>
  );
};
export function PulseConverter(version, value, unit) {
  if (value < -50000) {
    value = 0;
  }
  if (version === 2) {
    return Math.round(Number(value) / 9040);
  } else {
    return Math.round(Number(value) / 5650);
  }
}
