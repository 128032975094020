import {
  mdiBattery,
  mdiClose,
  mdiLightningBolt,
  mdiMapMarker,
  mdiWeb,
} from "@mdi/js";
import unstyledIcon from "@mdi/react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PulseConverter } from "./ListItem";
import Chart from "react-google-charts";
import NoteComponent from "./components/NoteComponent";
import TestProtocolComponent from "./components/TestProtocoll";

const OverLay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MainContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  border-style: solid;
  border-color: #4a6283;
  background-color: ${({ theme }) => "#fff"};
  padding: 0vh 1vh 1vh 1vh;
  min-width: 360px;
  overflow: scroll;
  height: 80%;

  p {
    font-size: 1.2vh;
    font-weight: bold;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  border-style: solid;
  border-color: black;
  background-color: ${({ theme }) => "#fff"};
  padding: 1vh;
  color: #4a6283;
  gap: 1vh;
`;
const SubContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1vh;
  gap: 1vh;
`;
const InnerText = styled.p`
  width: 100%;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh 0vh 1vh 0vh;
  border-radius: 1vh;
  gap: 1vh;
  white-space: nowrap;
  overflow: none;
  text-overflow: ellipsis;
`;
const StyledText = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 1vh;
`;
const LaneNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vh;
  border-style: solid;
  width: 12vh;
`;
const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vh;
  width: 100%;
  color: #4a6283;
`;
const StatsDivColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
`;
const StatsCon = styled.div`
  height: 1vh;
`;
const Icon = styled(unstyledIcon)`
  cursor: pointer;
  color: #4a6283;
`;
const LineContainer = styled.div`
  width: 100%;
  color: #4a6283;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
  align-items: center;
`;

const OpenedListItem = ({ onClick, item }) => {
  // console.log(item);
  const [status, setStatus] = useState(item.status);

  const [batteryHistory, setBatteryHistory] = useState(item.batteryHistory);
  const [isProtocol, setIsProtocol] = useState(false);

  const lastUpdate = new Date(item.lastUpdate);
  function GetOnline() {
    //time since last update > 1 minute
    if (new Date().getTime() - lastUpdate.getTime() > 60 * 5000) {
      return false;
    } else {
      return true;
    }
  }
  useEffect(() => {
    setStatus(item.status);
    setBatteryHistory(item.batteryHistory);

    return () => {};
  }, [item]);

  return status ? (
    <OverLay onClick={() => onClick()}>
      {item && (
        <MainContainer
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <LineContainer
            style={{
              position: "sticky",
              padding: "1vh",
              top: "0px",
              zIndex: "1",
              borderRadius: "0vh",
              backgroundColor: "white",
              borderStyle: "none none solid none",
            }}
          >
            <div style={{ display: "flex", gap: "2vh", alignItems: "center" }}>
              {item.facility === "Waco" && <h1>Box: {status.box}</h1>}
              <h1>Lane: {item.lane - item.lane.slice(0, -2) * 100}</h1>
            </div>
            <Icon path={mdiClose} size={2} onClick={() => onClick()} />
          </LineContainer>
          <Container>
            <SubContainer>
              <StyledText>
                <InnerText>
                  <Icon path={mdiWeb} size={1} />
                  {GetOnline() ? "Online" : "Offline"}
                </InnerText>
                <InnerText>
                  <Icon path={mdiBattery} size={1} />
                  {batteryHistory[0]
                    ? batteryHistory[batteryHistory.length - 1].batteryPercent +
                      "%"
                    : "-"}
                </InnerText>
              </StyledText>
              <StyledText>
                <InnerText>
                  <Icon path={mdiLightningBolt} size={1} />
                  {batteryHistory[batteryHistory.length - 1].charging
                    ? "Charging"
                    : "Not charging"}
                </InnerText>
                <InnerText>
                  <Icon path={mdiMapMarker} size={1} />
                  {status.stopPosition
                    ? PulseConverter(2, status.stopPosition, "yd") + " yd"
                    : "-"}
                </InnerText>
              </StyledText>
            </SubContainer>
          </Container>
          <StatsCon> </StatsCon>
          <StatsContainer>
            <InnerText>
              <a>Firmware.ver:</a>
              {status.softVer
                ? status.softVer.toString().split(".").length > 2
                  ? status.softVer
                  : status.softVer.toFixed(2)
                : "-"}
            </InnerText>
            <InnerText>
              <a>Id:</a>
              {status.uniqueId ? status.uniqueId : "-"}
            </InnerText>
            <InnerText>
              <a>Retriever ver:</a>
              {status.retVersion
                ? status.retVersion + "." + status.retSubVersion
                : "-"}
            </InnerText>
            <InnerText>
              <a>Charge rate:</a>
              {status.chargeRate
                ? Math.round(status.chargeRate).toFixed(1) + "A"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Battery volt:</a>
              {status.batteryVolt
                ? (status.batteryVolt / 100).toFixed(2) + "V"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Cell balance:</a>
              {status.cellBalance ? status.cellBalance + "mV" : "-"}
            </InnerText>
            <InnerText>
              <a>Battery temp:</a>
              {status.batTemp
                ? status.batTemp +
                  "°C / " +
                  ((Number(status.batTemp) * 9) / 5 + 32) +
                  "°F"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Docked:</a>
              {status.docked !== null
                ? status.docked === "true"
                  ? "True"
                  : "False"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Home low bat:</a>
              {status.homeLowBat
                ? status.homeLowBat === "1"
                  ? "True"
                  : "False"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Home timer:</a>
              {status.homeTimer ? status.homeTimer + " min" : "-"}
            </InnerText>
            <InnerText>
              <a>Shot.det:</a>
              {status.shotDetector !== null
                ? Number(status.shotDetector) >= 1
                  ? "True"
                  : "False"
                : "-"}
            </InnerText>
            <InnerText>
              <a>Lane scale:</a>
              {status.laneScale ? status.laneScale + "%" : "-"}
            </InnerText>

            <InnerText>
              <a>Shot sence:</a>
              {status.shotSense ? status.shotSense : "-"}
            </InnerText>
            <InnerText>
              <a>White light:</a>
              {status.white
                ? status.white === "true"
                  ? "On"
                  : status.white === "false"
                  ? "Off"
                  : status.white
                : "-"}
            </InnerText>
            <InnerText>
              <a>Blue/Red:</a>
              {status.redBlue
                ? status.redBlue === "true"
                  ? "On"
                  : status.redBlue === "false"
                  ? "Off"
                  : status.redBlue
                : "-"}
            </InnerText>

            <InnerText>
              <a>Wifi:</a>
              {status.wifiSignal ? status.wifiSignal + "dB" : "-"}
            </InnerText>

            <InnerText>
              <a>Sleeping:</a>
              {status.Inactivity ? status.Inactivity : "-"}
            </InnerText>
            <InnerText>
              <a>Uptime:</a>
              {status.upTime
                ? status.upTime.split(",")[0] + status.upTime.split(",")[1]
                : "-"}
            </InnerText>
          </StatsContainer>
          <LineContainer
            style={{
              justifyContent: "center",
              marginTop: "2vh",
              marginBottom: "0vh",
              padding: "1vh 0px 1vh 0px",
              borderStyle: "solid none none none",
              borderRadius: "0px",
            }}
          >
            <h1 style={{ fontStyle: "italic", textDecoration: "underline" }}>
              Battery history
            </h1>
          </LineContainer>

          <Chart
            style={{ cursor: "crosshair" }}
            chartType="LineChart"
            className="chart"
            width="100%"
            height="400px"
            data={GetData(batteryHistory)}
            options={{
              hAxis: {
                title: "Elapsed Time (hours:minutes)",
              },
              vAxis: {
                title: "Battery Percentage",
              },
              backgroundColor: "#fff",
              legend: {
                position: "top",
              },
              colors: ["#4a6283", "#fff"],
            }}
          />
          <h1
            style={{
              color: "#4a6283",
              fontStyle: "italic",
              textDecoration: "underline",
            }}
          >
            Notes
          </h1>
          <NoteComponent lane={item.lane} facility={item.facility} />
          {isProtocol && (
            <h1
              style={{
                color: "#4a6283",
                fontStyle: "italic",
                textDecoration: "underline",
              }}
            >
              Delivery protocol
            </h1>
          )}
          <TestProtocolComponent
            UID={status.uniqueId}
            isProtocol={(e)=>{setIsProtocol(e)}}
          />
        </MainContainer>
      )}
    </OverLay>
  ) : (
    <></>
  );
};

function GetData(data) {
  const dataForm = [
    { type: "string", label: "Elapsed Time (hours:minutes)" },
    "Battery Percentage",
  ];

  const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
  const startTime = new Date(sortedData[0].date).getTime();
  const endTime = new Date(sortedData[sortedData.length - 1].date).getTime();

  const formattedData = sortedData.map((item) => {
    const elapsedMinutes = (endTime - new Date(item.date).getTime()) / (1000 * 60);
    const hours = Math.floor(elapsedMinutes / 60);
    const minutes = Math.floor(elapsedMinutes % 60);
    const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;
    return [formattedTime, item.batteryPercent];
  });

  return [dataForm, ...formattedData];
}

export default OpenedListItem;
